<template>
  <v-dialog width="600" v-model="dialog" persistent>
    <v-card>
      <v-form @submit.prevent="submit()">
        <v-card-title>
          Formulário Opções
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-2">
            <v-col>
              <v-btn @click="reset()" small outlined>
                <v-icon left>mdi-keyboard-return</v-icon>
                Resetar
              </v-btn>
            </v-col>
          </v-row>
          <v-alert type="error" :value="hasErrors">
            {{ errorMessage }}
          </v-alert>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.name"
                :error-messages="errors.name"
                label="Nome"
                outlined
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                v-model="form.reference"
                :error-messages="errors.reference"
                label="Referência"
                outlined
              >
                <template v-slot:prepend-inner>
                  <v-icon small class="mt-1">mdi-pound</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> Cancelar </v-btn>
          <v-btn color="primary" type="submit" :disabled="!formChanged">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import FormDialogMixin from "@/mixins/FormDialogMixin.vue";
import { storeOption, updateOption } from "@/services/config/form_options";

export default {
  mixins: [FormDialogMixin],

  components: {},

  props: {
    category_id: [String, Number],
  },

  data() {
    return {};
  },

  methods: {
    async getSubmitQuery(form) {
      const payload = {
        ...form,
        category_id: this.category_id,
      };

      if (form.id) {
        return await updateOption(payload, form.id);
      }

      return await storeOption(payload);
    },
    submitResponseToData(response) {
      return response.data;
    },
  },

  computed: {},
};
</script>

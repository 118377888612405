<script>
import ToastsMixin from "@/mixins/ToastsMixin";

export default {
  mixins: [ToastsMixin],

  components: {},

  props: {},

  data() {
    return {
      dialog: false,
      form: {},
      errors: {},
      errorMessage: "",
      hasErrors: false,
      initialForm: {},
      submitting: false,
    };
  },

  methods: {
    async getSubmitQuery(form) {},
    submitResponseToData(response) {
      return response;
    },
    afterSubmit(response) {
      this.close();
      this.toast("Salvo!");
    },
    async submit() {
      this.submitting = true;

      try {
        const response = await this.getSubmitQuery(this.form);
        this.$emit("submitted", this.submitResponseToData(response));
        this.afterSubmit(this.submitResponseToData(response));
      } catch (error) {
        this.hasErrors = true;
        this.errorMessage = error.response?.data?.message || "Erro inesperado";

        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      } finally {
        this.submitting = false;
      }
    },
    open(form = {}) {
      this.dialog = true;
      this.initialForm = { ...form };
      this.reset();
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.form = { ...this.initialForm };
      this.resetValidation();
    },
    resetValidation() {
      this.hasErrors = false;
      this.errorMessage = "";
      this.errors = {};
    },
  },

  computed: {
    formChanged() {
      return !_.isEqual(this.form, this.initialForm);
    },
  },
};
</script>

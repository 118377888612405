<template>
  <div v-if="ready">
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            {{ data.name }}
            <v-spacer></v-spacer>
            <v-btn :to="{ name: 'form-options-categories' }" exact icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            Cód.: {{ data.id }}
            <v-btn @click="copy(data.id)" x-small icon title="Copiar">
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
            <br />
            Data registo: {{ data.created_at || "---" }} <br />
            Data última alteração: {{ data.updated_at || "---" }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Opções
            <v-btn
              @click="$refs.option_form.open()"
              color="primary"
              outlined
              class="ml-4"
              small
            >
              <v-icon left>mdi-plus</v-icon> Registar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data.options"
            item-value="id"
            hide-default-footer
            height="calc(100vh - 360px)"
            fixed-header
            :items-per-page="-1"
          >
            <template v-slot:item.name="{ item }">
              <a @click.prevent="$refs.option_form.open(item)">
                {{ item.name }}
              </a>
              <span v-if="item.reference" class="text--disabled">
                #{{ item.reference }}
              </span>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn @click="$refs.option_form.open(item)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click="handleDeleteOption(item)" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.integrations="{ item }">
              <v-chip
                v-for="code in item.codes"
                @click="$refs.option_code_form.open(code)"
                @click:close="handleDeleteCode(code)"
                :key="`${code.lead_provider_id}-${code.form_option_code_id}`"
                :title="code.code"
                small
                outlined
                label
                color="primary"
                link
                close
              >
                {{ code.lead_provider_id }}: {{ code.code }}
              </v-chip>

              <v-btn
                @click="
                  $refs.option_code_form.open({ form_option_id: item.id })
                "
                icon
                small
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <OptionFormDialog
      ref="option_form"
      :category_id="category_id"
      @submitted="refresh()"
    />
    <OptionCodeFormDialog ref="option_code_form" @submitted="refresh()" />
    <ConfirmationDialog ref="confirmation" />
  </div>
</template>

<script>
import QueryMixin from "@/mixins/QueryMixin.vue";
import OptionFormDialog from "@/components/formOptions/OptionFormDialog.vue";
import ConfirmationDialog from "@/components/tools/ConfirmationDialog.vue";
import {
  showCategory,
  deleteOption,
  destroyOptionCode,
} from "@/services/config/form_options";
import ToastsMixin from "@/mixins/ToastsMixin";
import OptionCodeFormDialog from "@/components/formOptions/OptionCodeFormDialog.vue";

export default {
  mixins: [QueryMixin, ToastsMixin],

  components: {
    OptionFormDialog,
    ConfirmationDialog,
    OptionCodeFormDialog,
  },

  props: {
    category_id: String,
  },

  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Cód.",
          value: "id",
          width: 100,
        },
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Integrações",
          value: "integrations",
          sortable: false,
          width: 200,
        },
        {
          text: "Ações",
          value: "action",
          sortable: false,
          width: 150,
        },
      ],
    };
  },

  methods: {
    async getQuery() {
      return showCategory(this.category_id);
    },
    responseToData(response) {
      return response.data;
    },
    handleNotFound() {
      this.toastError("Categoria não encontrada");
      this.$router.replace({
        name: "form-options-categories",
      });
    },
    copy(text) {
      try {
        navigator.clipboard.writeText(text);
        this.toast("Copiado");
      } catch (error) {
        this.toastError("Não foi possível copiar");
      }
    },
    handleDeleteOption(option) {
      this.$refs.confirmation.confirm(
        "Excluir opção '" + option.name + "'?",
        async ({ close, startLoading, stopLoading }) => {
          startLoading();
          try {
            await deleteOption(option.id);
            close();
            this.refresh();
          } catch (error) {
            this.toastError("Não foi possível excluir a cobertura");
          } finally {
            stopLoading();
          }
        },
        null,
        {
          color: "red",
          confirmText: "Excluir",
          description: "A ação será irreversível",
        }
      );
    },
    handleDeleteCode(code) {
      this.$refs.confirmation.confirm(
        `Excluir código ${code.lead_provider_id}?`,
        async ({ close, startLoading, stopLoading }) => {
          startLoading();
          try {
            await destroyOptionCode(code.form_option_id, code.lead_provider_id);
            close();
            this.refresh();
          } catch (error) {
            this.toastError("Não foi possível excluir a cobertura");
          } finally {
            stopLoading();
          }
        },
        null,
        {
          color: "red",
          confirmText: "Excluir",
          description: "A ação será irreversível",
        }
      );
    },
  },

  computed: {},

  watch: {
    $route(to) {
      this.refresh();
    },
  },

  created() {
    this.refresh();
  },
};
</script>

<template>
  <v-select
    :value="modelValue"
    @change="$emit('input', $event)"
    @focus="refresh()"
    v-bind="$attrs"
    :loading="loading"
    :items="data"
    item-value="id"
    item-text="name"
  ></v-select>
</template>

<script>
import QueryMixin from "@/mixins/QueryMixin";
import { index } from "@/services/lead_providers/lead_providers";

export default {
  mixins: [QueryMixin],

  components: {},

  props: {
    modelValue: Number,
  },

  model: {
    prop: "modelValue",
    event: "input",
  },

  data() {
    return {};
  },

  methods: {
    async getQuery() {
      return await index();
    },
    responseToData(response) {
      return response.data.data;
    },
  },

  computed: {},
};
</script>

<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card>
      <v-form @submit.prevent="submit()">
        <v-card-title>
          Formulário Integração
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-alert
            :value="hasErrors"
            type="error"
            v-text="errorMessage"
          ></v-alert>
          <v-text-field
            v-if="initialForm.lead_provider_id"
            :value="initialForm.lead_provider_id"
            label="Integração"
            outlined
            disabled
          ></v-text-field>
          <LeadProviderSelect
            v-else
            v-model="form.lead_provider_id"
            :errorMessages="errors.lead_provider_id"
            label="Integração"
            outlined
          />
          <v-text-field
            v-model="form.code"
            :error-messages="errors.code"
            label="Código"
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> Cancelar </v-btn>
          <v-btn type="submit" color="primary"> Salvar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import FormDialogMixin from "@/mixins/FormDialogMixin";
import LeadProviderSelect from "@/components/LeadProviders/LeadProviderSelect.vue";
import { storeOptionCode } from "@/services/config/form_options.js";

export default {
  mixins: [FormDialogMixin],

  components: {
    LeadProviderSelect,
  },

  props: {},

  data() {
    return {};
  },

  methods: {
    async getSubmitQuery(form) {
      return await storeOptionCode(form.form_option_id, form);
    },
  },

  computed: {},
};
</script>
